import React from 'react'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  bannerCtr: {
    backgroundColor: "#15684b",
    padding: "5rem",
    marginBottom: "3.5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "3rem 2rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3rem .5rem",
    },
  },
  h1: {
    fontSize: "2.5rem",
    textTransform: "uppercase",
    paddingBottom: "1rem",
    fontWeight: 700,
    color: "#fff",
    fontFamily: "'Source Sans Pro', sans-serif",
  },
  p: {
    color: "#fff",
    fontSize: "1.15rem",
  },
  h2: {
    fontWeight: 500,
    fontSize: "1.5rem",
    paddingBottom: "1rem",
  },
}))

export default function NewContact() {
  const classes = useStyles()

  return (
    <>
      <Container maxWidth="lg" className={classes.bannerCtr}>
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.h1}>
                Contact Snappy Web Design
              </Typography>
              <Typography variant="body1" className={classes.p}>
                Interested in doing business with us? Have questions? Let's
                talk!
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container maxWidth="md">
        <Typography variant="h2" className={classes.h2}>
          Let's get started.
        </Typography>
        <Typography variant="body1" className={classes.pAlt}>
          Fill out the form below and get ready! You'll hear back soon.
        </Typography>
        <Typography variant="body1" paragraph className={classes.pAlt} style={{marginBottom: '2rem'}}>
          Or, you can call us at 734-489-9055.
        </Typography>
      </Container>
    </>
  )
}
