import React from "react"
// import styled from "styled-components"
// import { Row, Col, Container } from "reactstrap"
// import { Col, } from "reactstrap"
import Container from '@material-ui/core/Container'
// import Link from "../components/link"
import Layout from "../components/layout"
import ContactForm from "../components/form"
import Grid from '@material-ui/core/Grid'
// import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFax } from "react-icons/fa"
// import Styled from "styled-components"
// import Hr from "../components/hr"
import { graphql } from "gatsby"
import Seo from "../components/seo"
// import NumberFormat from "react-number-format"
import PortfolioCTA from "../components/PortfolioCTA"
import NewContact from '../components/NewContact'

// let Cirle = Styled.div`
//   height: 4rem;
//   width: 4rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #b6e3d5;
//   color: #26a27b;
// `
// const Background = styled.div`
//   background: linear-gradient(60deg, #147452 25%, #26a27b 92.13%, #3bb18c 100%);
//   color: white;
//   padding: 8rem 0;
//   clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
//   text-align: center;
// `

// let ContactItem = ({ text, type, Icon, href }) => (
//   <Col
//     xs={6}
//     lg={3}
//     className="d-flex flex-column align-items-center"
//     style={{ wordBreak: "break-word" }}
//   >
//     <Cirle className="h-4-rem w-4-rem bg-primary-faded my-3 absolute-center rounded-circle">
//       <Icon size={30} />
//     </Cirle>
//     <div className="text-center">
//       <h6>{type}</h6>
//       <span className="text-secondary">
//         {href ? (
//           <Link to={href} className="reset">
//             {text}
//           </Link>
//         ) : (
//           text
//         )}
//       </span>
//     </div>
//   </Col>
// )

let Contact = ({ data }) => {
  // let { address, email, phone, fax } = data.site.siteMetadata
  return (
    <Layout>
      <Seo
        title="Contact Us"
        description="Get in touch with Snappy Web Design for your small business or startup website needs. The perfect modern websites for trailblazers."
      />
      {/* <Background>
        <h1>Contact Snappy Web Design</h1>
        <h6>
          Ready to start your next web design + development project? We're
          ready!
        </h6>
      </Background> */}
      {/* <Container>
        <Row className="py-5">
          <ContactItem text={address} type="Address" Icon={FaMapMarkerAlt} />
          <ContactItem
            text={email}
            href={`mailto:${email}`}
            type="Email"
            Icon={FaEnvelope}
          />
          <ContactItem
            text={
              <NumberFormat
                value={phone}
                displayType={"text"}
                format="###-###-####"
              />
            }
            href={`tel:${phone}`}
            type="Phone"
            Icon={FaPhone}
          />
          <ContactItem text={fax} type="Fax" Icon={FaFax} />
        </Row>
        <hr />
        <Row className="py-5">
          <Col md={6} className="m-auto">
            <h4 className="text-center text-secondary">Drop us a line</h4>
            <Hr />
            <ContactForm />
          </Col>
        </Row>
      </Container> */}
      <NewContact />
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12} sm={8}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
      <PortfolioCTA />
    </Layout>
  )
}

export const query = graphql`
  query Contact {
    site {
      siteMetadata {
        address
        email
        phone
        fax
      }
    }
  }
`

export default Contact
